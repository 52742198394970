import {
  type NewsletterSubscribeRequest,
  v1SitesSubscribeToNewsletterCreate,
} from '@on3/api';
import { Form } from '@on3/ui-lib/components/Form';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { useSite } from '@on3/ui-lib/index';
import { externalApi } from '@on3/ui-lib/src/utils/api';
import { yup } from '@on3/ui-lib/utils/yup';
import clsx from 'clsx';
import { Link } from 'components/Link';
import { useState } from 'react';

import styles, { on3Primary } from './SignUpModule.module.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
});

const defaultState: NewsletterSubscribeRequest = {
  email: '',
  requireConfirmation: true,
};

interface ISidebarSignUpProps {
  title?: string;
  className?: string;
  buttonText?: string;
  newsletterKey?: number;
}

export const SignUpModule = ({
  title,
  className,
  buttonText,
  newsletterKey,
}: ISidebarSignUpProps) => {
  const { currentSite } = useSite();
  const primaryColor =
    currentSite?.linkColor || currentSite?.primaryColor || on3Primary;

  const newsletterTitle = title || `${currentSite?.name} Newsletter`;
  const newsletterButtonText = buttonText || 'Subscribe to Newsletter';
  const [message, setMessage] = useState({ error: false, text: '' });
  const hasNewsletter = currentSite?.newsletterListId;
  const subscribeKey = newsletterKey
    ? newsletterKey
    : hasNewsletter
      ? currentSite?.key
      : 44;
  const [state, setState] = useState<NewsletterSubscribeRequest>(defaultState);

  const handleSubmit = async (values: NewsletterSubscribeRequest) => {
    try {
      await v1SitesSubscribeToNewsletterCreate(
        externalApi,
        subscribeKey,
        values,
      );
      setMessage({ error: false, text: 'Success!' });
      setState(defaultState);
    } catch (error) {
      setMessage({ error: true, text: 'Something went wrong.' });
      console.error('Error subscribing to newsletter:', error);
    } finally {
      setTimeout(() => {
        setMessage({ error: false, text: '' });
      }, 5000);
    }
  };

  return (
    <div className={clsx(styles.block, className)}>
      <div className={styles.container}>
        <h3>{newsletterTitle}</h3>
        <Form onSubmit={handleSubmit} schema={schema} values={state}>
          {({ errors, processing }) => (
            <div>
              <Input
                error={errors.email}
                id="email"
                label="Email"
                onChange={(e) =>
                  setState((prev) => ({ ...prev, email: e.target.value }))
                }
                placeholder="Email..."
                srLabel
                value={state.email}
              />
              <button
                disabled={processing}
                style={{ backgroundColor: primaryColor }}
                type="submit"
              >
                {newsletterButtonText}
              </button>
            </div>
          )}
        </Form>
        {!!message.text && (
          <p
            className={styles.message}
            data-type={message.error ? 'error' : 'success'}
          >
            {message.text}
          </p>
        )}
      </div>
      <p className={styles.terms}>
        {`By clicking "${newsletterButtonText}", I agree to On3's`}{' '}
        <Link href="/page/privacy-policy/">Privacy Notice</Link>,
        {' '}<Link href="/page/terms-of-service/">Terms</Link>,{' '}


        {`and use of my personal information described therein.`}
      </p>
    </div>
  );
};
